@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

* {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

a {
  text-decoration: none;
}

body {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  background-color: #FAF4E8;
}

.card {
  border-radius: 0px;
  border: 0px;
  background-color: #FAF4E8;
}

li {
  list-style: none;
  padding: 0px;
  margin: 0px;
}

.font-500 {
  font-weight: 500;
}

/* navbar start */
.navbar .logo-img {
  height: 200px;
  background-color: #fff;
  padding: 20px;
  border: 2px solid #A61524;
  border-left: 0px;
  border-top: 0px;
  border-radius: 0px 0px 30px 0px;
  position: absolute;
}

.navbar {
  background-color: #A61524;
}

.navbar .nav-item {
  background-color: #A61524;
  padding: 5px 15px;
  border-radius: 55px;
}

.navbar .extra-bg {
  position: absolute;
  width: 95%;
  background-color: #F58A0B;
  height: 125px;
  z-index: -1;
  border-radius: 0px 60px 60px 0px;
}

/* navbar end */

/* banner start */
.row {
  --bs-gutter-x: 0rem;
}

.banner {
  background-color: #FAF4E8;
  height: 1120px;
  width: 100%;
  padding-top: 20vh !important;
}

.banner .callNumber {
  font-size: 60px;
  border-radius: 80px 0px 0px 80px;
  background-color: #A61524;
}

.banner .about-heading {
  font-size: 55px;
  font-weight: 600;
  color: #F58A0B;
  margin-top: 20vh;
}

.banner .main-heading {
  font-size: 7vh;
  font-weight: 600;
  color: #F58A0B;
}

.banner hr {
  height: 4px;
  background-color: #A61524;
  opacity: 1;
  margin-left: -100px;
}

.banner .hr {
  background-color: #A61524 !important;
  opacity: 1;
  width: 20% !important;
}

.banner .name-color {
  color: #A61524;
}

.banner .register-btn {
  background-color: #F58A0B;
  border-radius: 50px;
  color: #fff;
}

.banner .more-btn {
  border: 3px solid #F58A0B;
  color: #F58A0B;
  border-radius: 50px;
}

.banner .main-side-img {
  height: 500px;
  border-radius: 25px;
  position: absolute;
  bottom: 10%;
  right: 5%;
  border-top: 10px solid #A61524;
  border-right: 10px solid #A61524;
}

.banner .middle-gapp {
  margin-top: 7%;
}

.banner .background-clr {
  background-color: #F58A0B;
  border-radius: 0;
  color: #fff;
}

.banner .background-clr-two {
  background-color: #A61524;
  border-radius: 0;
  color: #fff;
}

.banner .background-clr-three {
  background-color: #01a5a5;
  border-radius: 0;
  color: #fff;
}

.banner .fa-solid {
  font-size: 60px;
}

/* banner end */

.wel-back {
  background-color: #F58A0B;
}

.wel-back .welcome-text {
  font-size: 50px;
}

/* description start */
.descripTion {
  position: relative;
}

.descripTion .overview-heading {
  font-size: 55px;
  font-weight: 600;
  color: #A61524;
}

.descripTion hr {
  height: 4px;
  background-color: #A61524;
  opacity: 1;
  margin-left: -100px;
}

.descripTion .hr {
  background-color: #A61524 !important;
  opacity: 1;
  width: 20% !important;
}

.descripTion .overview-text {
  color: #A61524;
  font-weight: 500;
}

.descripTion .custom-circle {
  height: 100%;
  width: 80%;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
}

.descripTion .overview-img {
  height: 110%;
  position: absolute;
}

.descripTion .social-media {
  position: absolute;
  z-index: 1;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 35%;
  border-radius: 0px 25px 25px 0px;
}

.descripTion .social-media a {
  color: #A61524;
}

.descripTion .custom-bottom-left {
  width: 8%;
  background-color: #A61524;
  height: 40px;
  position: absolute;
  left: 0;
}

/* description end */

/* campus tour start */
.about-campus hr {
  background-color: #A61524;
  opacity: 0;
  position: absolute;
  right: 0;
}

.about-campus .hr {
  background-color: #A61524 !important;
  opacity: 1;
  width: 17% !important;
  position: absolute;
  right: 0;
  height: 4px;
  margin-top: 15px;
  z-index: 1;
}

.about-campus .campus-heading {
  font-size: 55px;
  font-weight: 600;
  color: #A61524;
}

.about-campus .campus-text {
  font-weight: 500;
  color: #A61524;
}

.about-campus .campus-btn {
  background-color: #A61524;
  color: #F58A0B;
}

.about-campus .college-students {
  height: 400px;
  width: 600px;
  border-radius: 25px;
}

.about-campus .college-img-bg {
  height: 450px;
  width: 670px;
  border-radius: 25px;
  background-color: #A61524;
  position: absolute;
  z-index: -1;
  top: -5%;
  left: -5%;
  transform: rotate(-10deg);
}

.about-campus .first-box {
  background-color: #F58A0B;
  padding: 20px 20px;
  margin-left: 20px;
  position: absolute;
  transform: rotate(45deg);
  right: 1%;
  z-index: 1;
}

.about-campus .second-box {
  background-color: #A61524;
  padding: 20px 20px;
  margin-left: 20px;
  position: absolute;
  transform: rotate(45deg);
  right: -3%;
}

/* campus tour end */

/* services start */
.services {
  position: relative;
  overflow: hidden;
}

.services .startline {
  position: absolute;
  left: 0%;
  width: 25%;
  background-color: #F58A0B;
  height: 18px;
}

.services .campus-heading {
  font-size: 55px;
  font-weight: 600;
  color: #A61524;
  margin-top: 4%;
}

.services .heading {
  font-size: 55px;
  font-weight: 600;
  color: #F58A0B !important;
}

.services .service-img {
  height: 715px;
  width: 853px;
  position: absolute;
  left: -6%;
}

.services .img-bg {
  overflow: hidden;
  height: 700px;
  background-color: #F58A0B;
  width: 700px;
  position: relative;
  border-radius: 50%;
}

.services .card {
  background-color: transparent;
  position: absolute;
  right: 0;
  width: 38%;
  top: 40%;
  height: 32%;
  overflow: hidden;
}

.services .card .card-body {
  color: #F58A0B;
}

.services .card-bg {
  background-color: #A61524;
  position: absolute;
  right: -10%;
  top: 28%;
  width: 50%;
  height: 75%;
  transform: rotate(45deg);
  border-radius: 20% 20%;
}

.services .services-btn {
  background-color: #F58A0B;
  color: #A61524;
  position: absolute;
  right: 12%;
  bottom: 20%;
}

.services .img-bg-two {
  position: absolute;
  background-color: #f58a0b3b;
  height: 95%;
  width: 20%;
  bottom: -7%;
  transform: rotate(45deg);
  border-radius: 48%;
}

/* services end */

/* student testimonials start */
.student-testimonials {
  background-color: #F58A0B;
}

.student-testimonials .testimonials-heading {
  font-size: 55px;
  font-weight: 600;
  color: #A61524;
}

.student-testimonials .swiper {
  width: 100%;
  height: 100%;
}

.student-testimonials .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.student-testimonials .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.student-testimonials .testimonials-btn {
  color: #F58A0B !important;
  background-color: #A61524 !important;
  font-size: 18px;
  font-weight: 500;
}

/* student testimonials end */

/* training-placement start */
.training-placement {
  position: relative;
}

.training-placement .card {
  background-color: #A61524;
}

.training-placement .card h2 {
  background-color: #F58A0B;
  color: #A61524;
  text-align: center;
  font-weight: 600;
  border-radius: 70px 70px 0px 0px;
}

.training-placement img {
  height: 200px;
  width: 200px;
  border-radius: 50%;
}

.training-placement ul {
  padding: 0;
}

.training-placement ul li {
  background-color: #F58A0B;
  margin-bottom: 25px;
  padding: 10px 10px;
  color: #A61524;
  font-size: 25px;
  font-weight: 600;
  text-align: center;
  border-radius: 15px;
}

.training-placement .training-placement-bg {
  position: absolute;
  bottom: 0;
  height: 50vh;
  width: 100%;
  left: 0;
  background-color: #01a5a5;
  border-radius: 80% 80% 0% 0%;
}

/* training-placement end */

/* student testimonials start */
.articles {
  background-color: #01a5a5;
}

.articles .articles-heading {
  font-size: 55px;
  font-weight: 600;
  color: #A61524;
  background-color: #F58A0B;
  border-bottom: 3px solid #A61524;
  border-radius: 45%;
  width: 15%;
  padding: 10px 20px;
}

.articles .swiper {
  width: 100%;
  height: 100%;
  background-color: #0e7c7c !important;
}

.articles .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.articles .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.articles .articles-btn {
  color: #F58A0B !important;
  background-color: #A61524 !important;
  font-size: 18px;
  font-weight: 500;
}

.student-testimonials .swiper-slide img {
  height: 300px;
  object-fit: unset;
}

.student-testimonials .swiper-slide .card-text {
  color: #A61524;
  font-weight: 500;
  font-size: 18px;
}

.articles .swiper-slide .card-text {
  color: #A61524;
  font-weight: 500;
  font-size: 18px;
  text-align: start;
}

.student-testimonials .swiper-slide {
  background-color: transparent;
}

/* student testimonials end */

/* footer start */
.Footer {
  background-color: #F58A0B;
}

.Footer .col-md-4 .card {
  background-color: #A61524;
  color: #F58A0B;
}

.swiper .swiper-button-prev {
  color: #A61524 !important;
  background-color: #F58A0B !important;
  padding: 30px;
  border-radius: 50%;
}

.swiper .swiper-button-next {
  color: #A61524 !important;
  background-color: #F58A0B !important;
  padding: 30px;
  border-radius: 50%;
}

.Footer a {
  color: #F58A0B;
  font-weight: 500;
}

.Footer .border-end {
  border-right: 3px dashed #F58A0B !important;
}

.Footer .border-top-start {
  border-radius: 30px 0px 0px 0px !important;
}

/* footer end */

/* footer bottom start */
.Footer-bottom .card {
  background-color: #c56d04;
  display: flex;
  justify-content: center;
  align-items: center;
}


.Footer-bottom a {
  color: #A61524;
  padding: 5px 15px;
  background-color: #F58A0B;
}

/* footer bottom end */

/* footer-bottom-two start */
.Footer-bottom-two .card {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #A61524;
  color: #F58A0B;
}

/* footer-bottom-two end */